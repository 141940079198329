import { useCallback, useEffect, useRef } from 'react';
import { constantFalseFn } from './constants';


/**
 * Проработан хук useConfirmOnLeave. Он выполняет ту же функцию, что и декоратор confirmOnLeave, но не создает
 * при этом новый компонент-обертку.
 *
 * Есть некоторые особенности:
 *   - Основной смысл работы декоратора confirmOnLeave заключался в том, что в него в качестве ПАРАМЕТРА, А НЕ ПРОПСОМ
 * ДЕКОРИРУЕМОГО КОМПОНЕНТА передавалась функция shouldConfirmFn, т.е. она была ПОСТОЯННОЙ. При этом, раз работа велась
 * с классами, и создавался класс обёртка, то получалось, что все пропсы попадали сначала в эту обертку, а потом
 * прокидывались в декорируемый компонент, т.е. в самом компоненте обертки пропсы были доступны просто в this.props.
 * Эти пропсы и передавались в момент вызова в постоянную функцию confirmOnLeave.
 *  - В случае с хуком, на первый взгляд, кажется, что всё не так. Хук может вызываться только внутри самого
 * компонента, а не как в случае с декоратором, когда компонент декорировался отдельно, т.е. создавался новый
 * компонент. Поэтому в случае с хуком, вполне логично хочется прямо в компоненте и подготовить все данные и передать
 * их в хук.
 * НО, тут следует учитывать особенность работы логики конфирмации. Вызов функции проверки того, нужен ли конфирм,
 * происходит исключительно по определенным событиям: покидание текущего роута приложения на другой роут приложения,
 * переход на какую то внешнюю ссылку или перезагрузка страницы. Кроме этих событий логику конфирмации ничего не
 * интересует. При этом, ещё нужно отметить, что сами описанные события, в большинстве случаев не связаны с событиями
 * изменения пропсов самого компонента, обычно это отдельные действия, когда пропсы компонента не изменяются (но
 * бывают и исключения, о них будет описано далее), например, вводим данные в форму, т.е. изменяем пропсы, закончили
 * вводить и пытаемся закрыть страницу, т.е. событие не связано с изменением пропсов.
 * Поэтому, если, как описывалось выше, в случае с хуком подготавливать данные, или, вообще, высчитывать саму логику
 * конфирмации внутри компонента, чтобы передать это в хук, то получится, что это будет происходить при каждом
 * изменение пропсов, т.е. на каждый рендер, что, по идее, абсолютно не нужно, учитывая, что события для конфирмации
 * могут, вообще, не наступить. Кроме того, т.к. в случае с хуком логика конфирмации реализуется через useEffect, это
 * приведет всё к постоянному созданию новых функцию обработчиков на указанные события и функции их очистки. Этого
 * всего хотелось бы избежать.
 *  - Поэтому, для хука реализуется логика, похожая на логику работы декоратора с классами, т.е:
 *      - Предполагается, что функция shouldConfirmFn будет передана сюда постоянная, т.е. будет определяться, как
 *        правило, вообще, вне функционального компонента и будет просто передаваться в хук. На всякий случай было
 *        решено помимо предположений обеспечивать это постоянство. Поэтому, независимо то кого, что передается,
 *        хук в любом случае запоминает только самую первую передаваемую ему функцию, как делают многие хуки,
 *        для своего initialValue, эта функция записывается в реф при первом рендере и больше не изменяется. Т.е.,
 *        это, в принципе, позволяет для удобства создавать и инлайновые стрелочные функции shouldConfirmFn при вызове
 *        хука, если сильно хочется, просто нужно иметь в виду, что они НЕ ДОЛЖНЫ ВНУТРИ СЕБЯ ЗАВИСЕТЬ ОТ ПРОПСОВ ИЗ
 *        SCOPE КОМПОНЕНТА, иначе запомнятся только самые первые значения пропсов. То, от чего зависит функция, будет
 *        передаваться вторым аргументом
 *      - Вторым аргументом в хук будут передаваться объект пропсов (или каких-то любых параметров, это не обязательно
 *        пропсы компонента), от которых зависит функция shouldConfirmFn. Логика тут такова, что, да, параметры
 *        постоянно передаются новые, но функция (а, соответственно, и вычисления, которые, могут быть трудоёмкими),
 *        будет вызвана с переданными ей этими параметрами только в момент возникновения описанных ранее событий. Т.е.,
 *        передаваемые вторым аргументом пропсы при каждом рендере просто сохраняются\обновляются в рефе внутри хука, а
 *        когда наступает событие, требующее конфирмации, то функция shouldConfirmFn вызывается с этими параметрами
 *        из рефа. Получаем, практически полную аналогию с работой декоратора confirmOnLeave, там пропсы были доступны
 *        по ссылке this, т.к. был компонент обертка, а в случае с хуком пропсы приходится передавать дополнительно в
 *        хук, а здесь они уже запоминаются по своей собственной ссылке. В случае с хуком, дополнительно, мы можем
 *        определить какие из пропсов надо передавать, тогда как в случае с декораторами в this.props были все
 *        пропсы декорируемого компонента, но, например, в то же время не было доступа к его локальному стейту. В
 *        случае с хуком, локальный стейт доступен и его тоже можно передать в хук.
 *  Таким образом, получается, что внутри хука и функция и пропсы существуют в виде рефов, поэтому обработчики в
 *  useEffect создаются 1 раз, если третий параметр, передаваемый в хук, текст сообщения конфирмации, не изменяется,
 *  чего, как правило, не происходит.
 *
 * Отдельно нужно отметить кейсы, которые упоминались ранее, когда события покидания роута происходят, практически
 * одновременно с изменением пропсов\стейта компонента. Они встречаются не очень часто, но их тоже нужно обрабатывать.
 * Например, кейс, когда какое-то действие приводит к редиректу на другую страницу, как правило, производится изменени
 * пропсов и потом сразу выполняется редирект. Например, форма на отдельном роуте, выполняем сабмит, в случае успеха,
 * редиректимся с этого экрана. Особенность этих ситуаций в том, что изменившиеся пропсы могут не успеть попасть
 * в хук и обновиться в рефе, т.к. новый рендер ещё не произошёл, а событие смены роута уже наступило и функция
 * shouldConfirmFn вызывается. Для таких случаев:
 *  - либо редирект должен выполняться после обновления пропсов в useEffect в самом компоненте, если в функции
 *  конфирмации сильно важно изменение этих пропсов
 *  - либо, можно в зависимости хука передать какой-то параметр в виде ссылки, оформленной в самом компоненте, при
 *  этом саму ссылку можно изменить (мутировать) непосредственно перед редиректом и её измененное значение уже
 *  будет доступно в shouldConfirmFn. Этот вариант, как правило, полезен в случае, когда нужно отменить стандартную
 *  логику конфирмации. Например, при работе с формой на отдельной роуте по обычной логике мы бы проверяли, изменились
 *  ли данные формы, т.е. нет ли несохраненных данных, но после сабмита мы понимаем, что данные только что были
 *  сохранены и нам нужен редирект, поэтому можно выставить флаг, которые отменяется обычную логику проверки при
 *  конфирмации
 *
 * Параметры:
 * @param shouldConfirmFn - функция, которая возвращает булевое значение, указывающее на то, нужно ли вызывать окно
 * конфирма в качестве входных параметров. При вызове принимает значение из второго параметра хука - props,
 * которые предварительно записываются во внутренний реф хука.
 * @param props - объект параметров, от которых зависит функция shouldConfirmFn, при каждом вызове функции записываются
 * во внутренний реф. При вызове конфирмации, параметры из рефа передаются в функцию в shouldConfirmFn
 * @param confirmMessage - текст сообщения, которое отображается в окне конфирма
 * @param history - объект History, передается на случай изменений
 *
 * */
export const useConfirmOnLeave = (
  shouldConfirmFn = constantFalseFn,
  props,
  confirmMessage = '',
  history,
) => {

  /*
  * Сохраняем функцию shouldConfirmFn во внутренний реф только при первом вызове хука, т.е. при инициализации
  * */
  const shouldConfirmRef = useRef(null);

  if (shouldConfirmRef.current === null) {
    shouldConfirmRef.current = shouldConfirmFn;
  }

  /*
  * Пропсы зависимости для shouldConfirmFn сохраняем и обновляем при каждом рендере внутренний реф для последующего
  * использования
  * */
  const propsRef = useRef();

  /*
  * Значение рефа для props обновляется каждый раз, когда изменяются данные в аргументе props.
  * */
  useEffect(
    () => {
      propsRef.current = props;
    },
    [props],
  );

  /*
  * При наступлении событий, в обработчиках, которые определяются далее по коду, функция shouldConfirmFn из рефа
  * вызывается с пропсами, их другого рефа, в котором они сохранялись и обноввлялись
  * */
  const routerWillLeave = useCallback(
    newLocationToLeave => {
      if (shouldConfirmRef.current(propsRef.current, newLocationToLeave)) {
        return confirmMessage;
      }
    },
    [shouldConfirmRef, propsRef, confirmMessage],
  );

  const confirmOnLeaveApp = useCallback(
    e => {
      /*
      * В качестве параметра в shouldConfirmFn передается новый объект location для предполагаемого перехода. В случае с
      * confirmOnLeaveApp, который вызывается на window.beforeunload (для отработки закрытия и перезагрузки вкладки
      * браузера), перехода никакого не осуществляется, поэтому параметром принято передавать null.
      * */
      if (!shouldConfirmRef.current(propsRef.current, null)) {
        return;
      }

      //eslint-disable-next-line no-param-reassign
      e.returnValue = confirmMessage;
      return confirmMessage;
    },
    [shouldConfirmRef, propsRef, confirmMessage],
  );

  useEffect(
    () => {
      window.addEventListener('beforeunload', confirmOnLeaveApp);
      const _unblockTransition = history.block(routerWillLeave);

      return () => {
        window.removeEventListener('beforeunload', confirmOnLeaveApp);
        if (_unblockTransition) {
          _unblockTransition();
        }
      };
    },
    [confirmOnLeaveApp, routerWillLeave],
  );
};
