import { useConfirmOnLeave } from './useConfirmOnLeave';
import { withConfirmOnLeave } from './withConfirmOnLeave';

export const confirmOnLeaveFactory = (confirmMessage, history) => {
  const _withConfirmOnLeave = (params) => withConfirmOnLeave({
    confirmMessage,
    history,
    ...params,
  });
  const _useConfirmOnLeave = (shouldConfirmFn, props, _confirmMessage = confirmMessage) =>
    useConfirmOnLeave(shouldConfirmFn, props, _confirmMessage, history);

  return {
    withConfirmOnLeave: _withConfirmOnLeave,
    useConfirmOnLeave: _useConfirmOnLeave,
  };
};
