import React, { Component } from 'react';
import { constantFalseFn } from './constants';


/*
* Декоратор добавляет к компоненту браузерный конфирм при переходе по роутингу \ закрытие вкладки браузера \
* перезагрузку вкладки браузера.
* Конфирм отображается, если переданный в декоратор функция shouldConfirmFn при вызове возвращает true.
* На вход в функцию shouldConfirmFn передаются:
*  - props декорируемого компонента
*  - при переходе по роутингу самого приложения передается объект location, описывающий куда пытаемся перейти. В случае,
*  если shouldConfirmFn вызван по событию window.beforeunload (при закрывании вкладки или перезагрузки страницы
*  в браузере), то этот второй параметр будет null. Т.е нужно иметь в виду при формировании shouldConfirmFn, что
*  функция будет вызываться и в случае изменения роутера, и в случае браузерных событий закрывания и перезагрузки
*  страницы, и во втором случае второй параметр будет равен null!
*
*
* ЕСЛИ ДЕКОРИРУЕМЫЙ КОМПОНЕНТ ЯВЛЯЕТСЯ КОНТЕЙНЕРОМ, ТО ДЕКОРАТОР ДОЛЖЕН ИДТИ В compose ПОСЛЕ connect
* export default compose(connect(), confirmOnLeave(fn, message))(Component)
* Если последовательность будет другая, то props из стора не попадут в функцию shouldConfirmFn
*
* ЭТО реализация декоратора через history.block вместо Prompt, т.к. через Prompt не удавалось просто реализовать
* кейсы, когда нужно отменить блокировку по определенному событию, например, форма в модальнике на отдельном роуте,
* переход с него надо блокировать, т.к. в форму вводились данные и ни могут быть потеряны, НО когда мы сохраняем форму,
* то мы должны закрыть модальник, т.е. произвести редирект. В этом случае условие блокировки по-прежнему выполняется,
* т.к. мы должны сначала дополнительно перерисовать Prompt с новым условием, а только потом сделать редирект. Сделать
* так зачастую проблематично из-за асинхронности выполнения колбеков (колбек сохранения) и самой перерисовки
* Prompt. Т.е., по идее, должны выполнить действие (сохранить форму на сервер), обновить стор или стейт с учетом того,
* что данные были сохранены, это приведет к перерисовке Prompt с новым условием что дизейблить уже не надо и только
* после этого нужно выполнить редирект. А это, вообще, непонятно как задетектировать, видимо, только мега заифлениями
* с дополнительными флагами в стейт и т.п. Ну и, в целом, это + 2 лишние перерисовки, без которых, вроде как, мы
* раньше нормально обходились, а тут придётся так заморачиваться.
* В дополнении ко всему этому, Prompt внутри себя тоже использует history.block, только условие он хочет получать именно
* из своих пропсов, т.е. требует перерисовку, а мы здесь немного корректируем это поведение и будем вычислять условие
* в момент самого редиректа, т.е. дополнительно можем использовать какие-то флаги из атрибутов классов, что мы раньше
* и делали через специальный хук.
*
* TODO ВАЖНО!
* C history.block, который здесь используется, были определенные непонятки, из-за которых у создателей роутера
* были идеи его даже удалить (https://github.com/ReactTraining/history/issues/690). В итоге, в ходе дискуссий
* было найдено решение непоняткам, метод не удалили, и, судя по "рекламе" новой версии, он, наоборот, скоро заживёт новой
* фееричной жизнью.
* На момент реализации через history.block и написания этого комментария новая версия history 5 с "крутой" реализацией
* history.block только была анонсирована. Её будет использовать новый react-router 6, который пока тоже только в альфе.
* Здесб пока используются предыдущие версии, которые, якобы с непонятками, но я их в наших кейсах не обнаружил. В
* любом случае, раз они имеются, надо будет обновиться побыстрее, чтобы к этим вопросам не возвращаться сразу же
* как зарелизят новый роутер. У новой версии обещают изменения, возможно, как раз с этим block, поэтому нужно будет
* корректно всё смигрировать тут по API + насколько я понял, возможно, не надо будет больше вешать обработчик на
* 'beforeunload' для обработки ф5, т.к. новый history.block, вроде как, сам отрабатывает всё на свете (но это, конечно
* же не, как обычно, не точно)
*
* */
export const withConfirmOnLeave = (
  {
    shouldConfirmFn = constantFalseFn,
    confirmMessage = '',
    history,
  },
) =>
  ComponentToDecorate => {
    class ConfirmOnLeaveWrapper extends Component {

      componentDidMount() {
        window.addEventListener('beforeunload', this.confirmOnLeaveApp);
        this._unblockTransition = history.block(this.routerWillLeave);
      }

      componentWillUnmount() {
        window.removeEventListener('beforeunload', this.confirmOnLeaveApp);
        if (this._unblockTransition) {
          this._unblockTransition();
        }
      }

      routerWillLeave = newLocationToLeave => {
        if (shouldConfirmFn(this.props, newLocationToLeave)) {
          return confirmMessage;
        }
      };

      confirmOnLeaveApp = e => {
        /*
        * Вторым параметром в shouldConfirmFn передается новый объект location для предполагаемого перехода. В случае с
        * confirmOnLeaveApp, который вызывается на window.beforeunload (для отработки закрытия и перезагрузки вкладки
        * браузера), перехода никакого не осуществляется, поэтому вторым параметром принято передавать null.
        * */
        if (!shouldConfirmFn(this.props, null)) {
          return;
        }

        //eslint-disable-next-line no-param-reassign
        e.returnValue = confirmMessage;
        return confirmMessage;
      };

      render() {
        return (
          <ComponentToDecorate {...this.props} />
        );
      }
    }

    return ConfirmOnLeaveWrapper;
  };
